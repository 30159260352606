<template>
  <div class="contact">
    <ValidationObserver ref="contactForm">
      <b-form>
        <b-row class="mt-2">
          <b-col cols="12">
            <b-card class="m-auto w-100">
              <b-row>
                <b-col cols="12">
                  <ek-input
                    v-model="contactDto.email"
                    label="البريد الالكتروني"
                    :rules="[
                      {
                        type: 'email',
                        message: 'يرجى ادخال عنوان بريد الكتروني صحيح',
                      },
                    ]"
                    name="البريد الالكتروني"
                    placeholder="أدخل البريد الالكتروني"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="6">
                  <ek-input
                    v-model="contactDto.phoneNumber"
                    name="رقم الهاتف"
                    label=" رقم الهاتف "
                    :rules="[
                      { type: 'phone', message: 'يرجى ادخال رقم هاتف صحيح' },
                    ]"
                    placeholder="أدخل رقم الهاتف"
                  ></ek-input>
                </b-col>
                <b-col cols="12" md="6">
                  <ek-input
                    v-model="contactDto.phoneNumber2"
                    name="رقم الهاتف 2"
                    label="رقم الهاتف 2 "
                    :rules="[
                      { type: 'phone', message: 'يرجى ادخال رقم هاتف صحيح' },
                    ]"
                    placeholder="أدخل رقم الهاتف 2"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="6">
                  <ek-input
                    v-model="contactDto.mobileNumber"
                    name=" رقم الجوال "
                    label=" رقم الجوال "
                    placeholder=" أدخل رقم الجوال"
                    :rules="[
                      { type: 'mobile', message: 'يرجى ادخال رقم جوال صحيح' },
                    ]"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="6">
                  <ek-input
                    v-model="contactDto.mobileNumber2"
                    name="رقم الجوال 2 "
                    label="رقم الجوال 2 "
                    placeholder=" أدخل رقم الجوال 2"
                    :rules="[
                      { type: 'mobile', message: 'يرجى ادخال رقم جوال صحيح' },
                    ]"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="6" lg="3">
                  <ek-input
                    v-model="contactDto.facebookLink"
                    name="Facebook"
                    label="Facebook"
                    placeholder="ادخل عنوان فيسبوك"
                    :rules="[{ type: 'url', message: 'يرجى رابط فيسبوك صحيح' }]"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="6" lg="3">
                  <ek-input
                    v-model="contactDto.whatsapp"
                    placeholder="ادخل عنوان واتساب"
                    name="whatsapp"
                    label="Whatsapp"
                    :rules="[
                      {
                        type: 'mobile',
                        message: 'whatsapp يجب ان يحتوي رقم جوال ',
                      },
                    ]"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="6" lg="3">
                  <ek-input
                    v-model="contactDto.instagramLink"
                    placeholder="ادخل عنوان انستغرام"
                    name="instagram"
                    label="instagram"
                    :rules="[
                      {
                        type: 'url',
                        message: 'يرجى ادخال رابط انستغرام صحيح ',
                      },
                    ]"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="6" lg="3">
                  <ek-input
                    v-model="contactDto.linkedinLink"
                    placeholder="ادخل عنوان Linkedin"
                    name="linkedin"
                    label="Linkedin"
                    :rules="[
                      {
                        type: 'url',
                        message: 'يرجى ادخال رابط linkedin صحيح ',
                      },
                    ]"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="4" v-if="false">
                  <ek-input
                    v-model="contactDto.behanceLink"
                    placeholder="ادخل عنوان behance"
                    name="behance"
                    label="Behance"
                    :rules="[
                      {
                        type: 'url',
                        message: 'يرجى ادخال رابط behance صحيح ',
                      },
                    ]"
                  ></ek-input>
                </b-col>

                <b-col cols="12" md="4">
                  <ek-input-image
                    noDeleteBtn
                    @input="contactDto.aboutFile1 = $event"
                    :value="contactDto.aboutImage1?
                      $store.getters['app/domainHost'] +
                      '//' +
                      contactDto.aboutImage1:'/media/placeholder/image-placeholder.jpg'
                    "
                    title="انقر لتحميل صورة"
                    name="instagram"
                    label="صورة حولنا 1"
                  ></ek-input-image>
                </b-col>
                <b-col cols="12" md="4">
                  <ek-input-image
                    noDeleteBtn
                    @input="contactDto.aboutFile2 = $event"
                    :value="contactDto.aboutImage2?
                      $store.getters['app/domainHost'] +
                      '//' +
                      contactDto.aboutImage2:'/media/placeholder/image-placeholder.jpg'
                    "
                    title="انقر لتحميل صورة"
                    name="instagram"
                    label="صورة حولنا 2 "
                  ></ek-input-image>
                </b-col>
                <b-col cols="12" md="4">
                  <ek-input-image
                    noDeleteBtn
                    @input="contactDto.aboutFile3 = $event"
                    :value="contactDto.aboutImage3?$store.getters['app/domainHost'] +'//' +contactDto.aboutImage3:'/media/placeholder/image-placeholder.jpg'
                    "
                    title="انقر لتحميل صورة"
                    name="instagram"
                    label="صورة حولنا 3"
                  ></ek-input-image>
                </b-col>
              </b-row>
              <template #footer>
                <b-button class="px-3" variant="primary" @click="submitForm">
                  تعديل <unicon fill="#fff" height="16px" name="pen"
                /></b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EkInput from "@/EK-core/components/EK-forms/EK-input-text";
import EkInputImage from "@/EK-core/components/EK-forms/EK-input-image";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    ValidationObserver,
    EkInput,
    EkInputImage,
  },
  computed: {
    ...mapState({ contactDto: (state) => state.settings.contactDto }),
  },
  methods: {
    ...mapActions(["setSettings", "getSettings"]),
    submitForm() {
      this.$refs.contactForm.validate().then((suc) => {
        if (suc) {
          let formData = new FormData();
          for (const key in this.contactDto) {
            formData.append(key, this.contactDto[key]);
          }
          this.setSettings(formData);
        }
      });
    },
  },
  created() {
    this.getSettings();
  },
};
</script>

<style lang='scss'>
.contact {
  button {
    font-size: 16px !important;
  }
}
</style>